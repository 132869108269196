/*******************
 ** Device Desktop **
 *******************/
 .desktop {
     section.welcome {
         .cover-mobile { display: none; }
         .background { display: ''; }
     }
 }

/*******************
 ** Device Mobile **
 *******************/
 .mobile {
     section.welcome {
         .cover-mobile { display: ''; }
         .background { display: none; }
     }
 }
