.app {
  section.navigation{
    .bar{
      width: calc(100% - 50px);
      height: 70px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      .list{
        display:block;

        ul{
          display: flex;
          align-items: center;

          .dropdown{
            overflow: hidden;

            .dropbtn{
              text-align: center;
              padding: 10px 0px;
              width: 160px;
              border: 1px solid #414141;
            }

            .dropdown-content {
              display: none;
              position: absolute;
              background-color: #fee7cd;
              min-width: 162px;
              box-shadow: 0px 4px 10px -2px rgba(0,0,0,0.2);
              z-index: 1;
              transition: all ease 0.35s;

              a{
                display: block;
                height: 50px;
                line-height: 50px;
                text-align: center;
                transition: all ease 0.3s;
              }

              a:hover{
                background-color: #414141;
                color:white;
              }
            }
          }

          /* Show the dropdown menu on hover */
          .dropdown:hover .dropdown-content {
            display: block;
          }

          
        }
      }
      .button{
        display:none;
      }
    }
  }
  section.news{
    &.news-header {          
      .header-copy {                    
          h1 {        
          }
      }
      .header-dark-bg {

      }
      .blue-bg {

      }
    }

    &.sub-path-container {
      &.show {

      }

      .main-path {

      }
      .header-arrow {

      }
      .sub-path {

      }
    }

    &.news-lists-container {      
      margin: 60px auto 60px auto;

      .news-lists {
        display: flex;   

        .date-container {
          min-width: 300px;
          margin-top: 30;

          .title {

          }
          .date {

          }
        }
        .news-container {
          border-bottom: none;

          .header-copy {
            h2 {
              margin-top: 20px;
              font-size: 24px;
              line-height: 30px;
            }
          }
          .main-copy {
            .copy-block {
              p {                        
                         
              }
              .sub-header {

              }
              .main-copy-container {
                font-size: 14px;
                line-height: 22px;
              }
            }                                              
          }
          .read-more-btn {
            margin-top: 30px;

            p {
              font-size: 17px;
            }

            &:hover {
              p {

              }
            }
          }
          .article-img {
            display: block;
            margin: 60px 0px 0px 0px;

            img {
                width: 100%;
            }
          }
        }
      }
    }
  }
  section.video {
      .video-container {

          iframe {
              width: 700px;
              height: 390px;
              margin: 0 auto;
          }

          .close-btn {
              right: 0px;
          }
          
      }
      .overlay {

      }
  }

  section.fastmelt {
    .cell-image {
      img {
        width: 583px !important;
        height: 583px;
        margin: 100px 0 0 0;
      }
    }
  }
}