html.distribution{
    scroll-behavior: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

section.distribution-navigation{
    #background-dark{
        background-color: #414141;
    }
    .menu{
        background-color: #414141 !important;

        ul{
            margin-top: 105%;
            position: relative;
            top: inherit;
            width: 100%;

            li{
                .text {
                    span {
                        a {
                            color:white !important;
                        }
                    }
                }
            }
        }
    }
    .bar {
        width:calc(100% - 32px);
        display: flex;

        .logo{
            width:115px;

            a{
                display: block;
                img{
                    display: block;
                    width:100%;
                }
            }
            
        }
        .list {
            ul {
                li{ 
                    .text{
                        a {
                            color: white !important;
                        }
                    }

                    .underline:after{
                        background: white !important;
                    }
                    
                }
            }
        }
        .button{
            height:24px !important;
            .bar{
                background-color: white !important;

                &.line-1 {
                    top:0;
                }

                &.line-2 {
                    top:10px;
                }

                &.line-3 {
                    bottom:0;
                }
            }
        }
    }
}
.distribution__hero{
    width:100%;
    height:85vh;
    background-image: url('../images/distribution_hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .header-text{
        text-align: center;
        width:calc(100% - 32px);

        h1{
            margin: 0;
            padding: 0;
            font-family: $roman;
            color:white;
            font-weight: 500;
            font-size: 1.5em;
        }

        .line{
            width:114px;
            height:2px;
            background-color:white;
            margin: 20px auto 0; 
        }

        p{
            color: white;
            font-size: 0.8em;
            font-family: $roman;
            font-weight:500;
            padding-top:30px;
        }
    }
}

.bottom-border{
    width:100%;
    height:24px;
    background-color: $cream;
    margin-top: -12px;
}

.distribution__overview{
    width:calc(100% - 32px);
    margin:50px auto;

    .title{
        color: $color-blue-dark;
        font-size:1.5em;
        font-family: $roman;
        font-weight:500;
        padding:0;
        margin:0;
    }

    .line{
        width:114px;
        height:2px;
        background-color:$color-blue;
        margin: 10px 0 30px; 
    }

    p{
        margin-bottom: 25px;
        font-size: 0.8em;
        font-family: $roman;
        font-weight:500;
        color: $darkBlue;
        line-height: 1.4;
    }
}

.distribution__options{
    padding: 50px 0;
    width:100%;
    background-color: $cream;

    .option{
        width:calc(100% - 32px);
        margin:0px auto 30px;

        h3{
            text-transform: uppercase;
            color: $color-blue;
            font-family: $heavy;
            font-size:0.7em;
        }

        p{
            font-size: 0.8em;
            font-family: $roman;
            font-weight:500;
            color: $darkBlue;
            line-height: 1.4;
            padding-bottom:10px;
        }

        ul{
            font-size: 0.8em;
            font-family: $roman;
            font-weight:500;
            color: $darkBlue;
            line-height: 1.4;
            padding-left: 20px;

            li{
                padding-bottom: 10px;
            }
        }
    }

    .option:last-of-type{
        margin: 0 auto 0;
    }
}

.distribution__form{
    margin:50px 0;

    h2{
        color: $color-blue-dark;
        font-size:1.5em;
        font-family: $roman;
        font-weight:500;
        padding:0;
        margin:0;
        text-align: center; 
    }

    .line{
        width:114px;
        height:2px;
        background-color:$color-blue;
        margin: 10px auto 30px; 
    }

    form{
        width: calc(100% - 32px);
        margin:auto;

        div{
            margin-bottom: 20px;
            .form-label{
                font-size: 0.7em;
                text-transform: uppercase;
                font-family: $heavy;
                font-weight: 500;
                color: $color-blue;
            }
            input{
                width:100%;
                border: 1px solid #D8D8D8;
                border-radius: 4px;
                height:30px;
                font-family: $roman;
                font-weight:500;
                color: $darkBlue;
                font-size:0.8em;
                line-height: 30px;
                // padding-left:5px;
            }
            select{
                width:100%;
                border: 1px solid #D8D8D8;
                border-radius: 4px;
                height:35px;
                font-family: $roman;
                font-weight:500;
                color: $darkBlue;
                font-size:0.8em;
                line-height: 30px;
                background-color: white;

                option{
                    font-family: $roman;
                    font-weight:500;
                    color: $darkBlue;
                    font-size:0.8em;
                    line-height: 30px;
                }
            }
            textarea{
                width:100%;
                border: 1px solid #D8D8D8;
                border-radius: 4px;
                font-family: $roman;
                font-weight:500;
                color: $darkBlue;
                font-size:0.8em;
            }
        }

        .button-wrapper{
            display: flex;
            justify-content: center;

            .form-submit-button{
                background-color: $color-blue;
                color: white;
                font-family: $roman;
                font-weight: 500;
                font-size: 0.7em;
                text-align: center;
                width:165px;
                height:40px;
                line-height:30px;
                border-radius: 4px;
                border: none;
    
            }
        }

    }

    .thankyou-message{
        text-align: center;
        color:$color-blue;
        font-family: $roman;
        font-size: 16px;
        font-weight:500;
    }
}

@media screen and (min-width: 600px) {
    .distribution__hero{
        height:75vh;

        .header-text{
            h1{
                font-size: 40px;
                line-height: 55px;
            }
            p{
                font-size:1.1em;
                line-height: 28px;
                max-width: 596px;
                margin: auto;
            }
        }
    }

    .distribution__overview{
        width:calc(100% - 80px);
        margin:100px auto;

        .title{
            font-size:40px;
            line-height: 55px;
        }
    
        p{
            margin-bottom: 35px;
            font-size: 1.1em;
            line-height: 28px;
        }
    }

    .distribution__options{
        padding: 100px 0;
    
        .option{
            width:calc(100% - 80px);
            margin:0px auto 40px;

            h3{
                font-size:1em;
            }
    
            p{
                font-size: 1.1em;
                line-height: 28px;
            }
    
            ul{
                font-size: 1.1em;
                line-height: 28px;

            }
        }
    }

    .distribution__form{
        margin:100px 0;
    
        h2{
            font-size:40px;
            line-height: 55px; 
        }

        form{
            width: calc(100% - 80px);
            max-width:726px;
            margin:60px auto 0;

            .row{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;

                div{
                    margin-bottom:0;
                }

                select{
                    height:32px;
                }
            }

            .button-wrapper{
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .distribution__overview{
        width:calc(100% - 120px);
        max-width:1440px;
        
        p{
            width: 75%;
        }
    }

    .distribution__options{
        .option:first-of-type{
            max-width:1440px;
            width:calc(100% - 120px);
            p{
                width:75%;
            }
        }

        .grid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            width:calc(100% - 120px);
            max-width:1440px;
            margin:auto;

            .option{
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .distribution__options{
        .grid{
            grid-gap: 60px;
        }
    }
}