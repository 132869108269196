.app {
  section.navigation{
    .bar{
      .list{
        ul{
          li{
            margin-left: 50px;
          }
        }
      }
    }
  }
  section.overview{

    .pharmacy{
      padding: 50px;
      justify-content: space-between;
        .logos{
          grid-row-gap: 0;
          grid-column-gap: 50px;

            img{
              width: 230px;
              height: 100px;
            }
        }
    }
}
  section.products{
    .products{
        .product-content{
            min-height:655px;

            .container{
                min-height:400px;
            }
        }
    }
}
  section.news{
    &.news-header {          
      .header-copy {                    
          h1 {
            left: 0px;             
          }
      }
      .header-dark-bg {

      }
      .blue-bg {

      }
    }

    &.sub-path-container {
      &.show {

      }

      .main-path {

      }
      .header-arrow {

      }
      .sub-path {

      }
    }

    &.news-lists-container {   
  
      .news-lists {
        .date-container {
          .title {

          }
          .date {

          }
        }
        .news-container {


          .header-copy {
            h2 {

            }
          }
          .main-copy {
            .copy-block {
              p {                        
                         
              }
              .sub-header {

              }
            }                                             
          }
          .read-more-btn {
            p {

            }
          }
        }
      }
    }
  }

  section.fastmelt {
    .cell-image {
      img {
        margin: 100px 0 0 100px;
      }
    }
  }
}