.app {
//   section.navigation {

//       .bar {
//           position: relative;
//           width: 100%;
//           height: 40px;

//           .list {
//               position: absolute;
//               bottom: 0px;
//               right: 0px;
//               height: 18px;

//               ul {
//                   list-style: none;

//                   li {
//                       font-size: 10px;
//                       margin-left: 5px;
//                       margin-right: 5px;
//                       padding-left: 10px;
//                       padding-right: 10px;
//                   }
//               }
//           }
//       }
//   }

section.navigation {
    .background {
      height: 70px;
    }

    .menu {
        display: block;
    }

    .bar {
        width: calc(100% - 32px);
        height: 70px;
        display:flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            position: relative;
            // float: left;
            img {
                // height: 50%;
            }
        }

        .list {
          display: none;
        }

        .button {
          display: block;
        }
    }
  }

  section.hero {
    position: relative;
    width: 100%;
    height: 75vh;

    .header-image {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../images/hero-bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .header-text {
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translate3d(-50%, -50%, 0);
      width:100%;

        .header-text-descript {
            h2 {
              font-family: 'AvenirLight', sans-serif;
              color: #414141;
              font-size: 25px;
              line-height: 29px;
              text-align: center;
              width: 90%;
              margin: auto;
              padding-left:0;

              span{
                font-family: 'AvenirHeavy', sans-serif;
              }
            }
        }
    }

    .bottom-border{
        position:absolute;
        bottom:0;
        width:100%;
        height:24px;
        // background-color: #104BA0;
    }
}

section.overview{
    .content{
        .flex{
          flex-direction: column;
  
          .descript{
            .accordion{
              font-size:18px;
              padding:10px 0;
            }
          }
          .images{
            margin-top:30px;
            img{
              width:100%;
            }
          }
        }
    }
    .pharmacy{
        flex-direction: column;
        width:100%;
        padding:50px 0;

        .text{
            width:90%;
            margin:auto;

            p{
                width:100%;
            }
        }
        .logos{
            width: 90%;
            margin:auto;
            grid-row-gap: 0;
            grid-column-gap: 25px;
    
            img{
              width: 200px;
              height: 100px;
            }
        }
    }
}

  section.products{
    .content{
        .flex{
            flex-direction: column;

            .icons{
                margin-top:20px;
                flex-wrap: wrap;
                justify-content: center;

                img{
                    margin:5px;
                }
            }
        }
    }
    .products{
        flex-direction: column;
        width:85%;

        .panel{
            .links{
                display: flex;
                flex-direction: row;
                .life {
                    margin-right: 30px;
                }
                .unichem {
                    margin-right:30px;
                }
            }
        }
    }
  }

  section.device-hero {
    position: relative;
    width: 100%;
    height: 75vh;

    .header-image {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/website/devices/devices-bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .header-text {
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translate3d(-50%, -50%, 0);
      width:100%;

        .header-text-descript {
            h2 {
              font-family: 'AvenirLight', sans-serif;
              color: #ffffff;
              font-size: 25px;
              line-height: 29px;
              text-align: center;
              width: 90%;
              margin: auto;
              padding-left:0;

              span{
                font-family: 'AvenirHeavy', sans-serif;
              }
            }
        }
    }

    .bottom-border{
        position:absolute;
        bottom:0;
        width:100%;
        height:24px;
        background-color: #104BA0;
    }
}

  section.focus-title {

    p{
        font-size:14px;
        width:initial;
    }
  }

  section.focus-content{

    .flex-container{
      display: flex;
      flex-direction: column;
    }
}

  section.highlight {
      height: 120px;

      p {
          max-width: 530px;
          span {
              font-size: 13px;
          }
      }

      .highlight-tab {
          transform: translate3d(-50%, -95%, 0);
      }
  }

  section.devices-about{
    width:100%;
    justify-content: center;

    .content{
        width:90%;
        margin: 0 auto;

        .title{
            color:white;
            font-size:40px;
        }

        .descript{
            color:white;
            width:90%;

            p{
                font-size:22px;
                line-height:30px;
            }
            
        }
        
    }
  }

  section.section-row {

    .products{
        flex-direction: column;
        width:85%;
    }

      .row {
          max-width: 700px;

          .cell {
              width: 100%;

              .content {

                  .title {
                      font-size: 40px;
                  }

                  .subtitle {
                      font-size: 19px;
                  }

                  .descript {
                      font-size: 10px;
                      line-height: 17px;
                  }

                  .list {
                      width: 100%;
                      margin-top: 20px;
                      margin-bottom: 20px;

                      ul {
                          list-style: none;

                          li {
                              .item {

                                  .text {
                                      font-size: 13px;
                                  }

                                  .arrow {
                                      width: 12px;
                                      height: 12px;
                                      padding: 5px;

                                      &.circle {

                                          img {
                                              width: 80%;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }

                  .instructions {
                      p {
                          font-size: 11px;

                          span {
                              padding-right: 10px;

                              &.bar {
                                  padding-left: 10px;
                              }
                          }
                      }
                  }
              }
          }

        .full-width{
            width:100%;

            .sub-info{
                color:#062757;
                font-size:16px;
                line-height:24px;
                width:100%;
            }
        }

          .line {

              .content {

                  .title {
                      font-size: 40px;
                  }

                  .subtitle {
                      font-size: 19px;
                  }

                  .descript {
                      font-size: 10px;
                      line-height: 17px;
                  }

                  .instructions {
                      p {
                          font-size: 11px;

                          span {
                              padding-right: 10px;

                              &.bar {
                                  padding-left: 10px;
                              }
                          }
                      }
                  }

                  .link {
                      margin-top: 50px;
                      margin-bottom: 50px;
                      width: 100%;

                      a {
                          font-size: 14px;
                          padding: 10px;
                          padding-left: 30px;
                          padding-right: 30px;
                      }
                  }
              }
          }
      }
  }

  section.contact {
      .row {
          max-width: 760px;

          .cell {

              .content {
                  padding: 25px;
                  padding-top: 30px;
                  padding-bottom: 30px;

                  .title {
                      font-size: 40px;
                  }

                  .descript {
                      font-size: 10px;
                      line-height: 20px;
                  }
              }
          }
      }
  }

  section.news{
    &.news-header {
      height: 230px;
      background-position: top;

      .header-copy {        
          h1 {
            font-size: 28px;
            top: 160px;
          }
      }
      .header-dark-bg {

      }
      .blue-bg {

      }
    }

    &.sub-path-container {
      &.show {

      }

      .main-path {

      }
      .header-arrow {

      }
      .sub-path {

      }
    }

    &.news-lists-container {   
  
      .news-lists {
        .date-container {
          .title {

          }
          .date {

          }
        }
        .news-container {

          .header-copy {
            h2 {

            }
          }
          .main-copy {
            .copy-block {
              p {                        
                         
              }
              .sub-header {

              }
            }                                                                
          }
          .read-more-btn {          
            p {

            }

            &:hover {
              p {

              }
            }
          }
          .article-img {
            display: none;
          }
        }
      }
    }
  }

  section.footer {
      width: 100%;
      text-align: center;
      border-top: 6px solid $color-blue;

      .text {
        padding: 20px;

        p {
            font-size: 10px;
            padding: 0
        }
      }
  }

  section.video {
    .video-container {

        iframe {
            width: 480px;
            height: 280px;
            margin: 0 auto;
        }

        .close-btn {
            right: 0px;
        }
        
    }
    .overlay {

    }
  }
}