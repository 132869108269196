/*=================================
=            VARIABLES            =
=================================*/

$font-AzoSans-Regular: 'AzoSans-Regular';
$font-AzoSans-Light: 'AzoSans-Light';
$font-AzoSans-Thin: 'AzoSans-Thin';

$heavy: 'AvenirHeavy';
$roman: 'AvenirRoman';


$cream: #FCF3F1;
$darkBlue: #062757;
$color-white: #ffffff;
$color-blue: #00aeef;
$color-blue-dark: #104ba0;
$color-light-dark: #2b4770;
$color-black: #000000;
$color-gray-light: #f4f4f4;
$color-blue-line: rgba(#1b53a4, 0.15);
$color-blue-light-line: #7aabf0;
$color-news-header-grey: #969696;

/*===============================
=            MIXIN's            =
===============================*/

@keyframes cursorAnimation {
    0%   { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@import "mixins/flex";
@import "mixins/animation";

/*====================================================
=            RESET, DEFAULT's and HELPERS            =
====================================================*/
@import "normalize";
@import "common";
@import "fonts";

/*==============================
=            IMPORTS           =
==============================*/

@import "layout/index";


