//distribution page
@import "distribution";


// basic
@import "basic";


// devices
@import "devices";

// media: xs
@media only screen and (max-width: 599px) {
    @import "xs";
}

// media: gt-xs
@media only screen and (min-width: 600px) and (max-width: 736px) {
    @import "gt-xs";
}

// media: sm
@media only screen and (min-width: 737px) and (max-width: 959px) {
    @import "sm";
}

// media: gt-sm
@media only screen and (min-width: 960px) {
    @import "gt-sm";
}

// media: md
@media only screen and (min-width: 960px) and (max-width: 1279px) {
    @import "md";
}

// media: gt-md
@media only screen and (min-width: 1280px) {
    @import "gt-md";
}

// media: lg
@media only screen and (min-width: 1280px) and (max-width: 1919px) {
    @import "lg";
}

// media: gt-lg
@media only screen and (min-width: 1920px) {
    @import "gt-lg";
}

// media: xl
@media only screen and (min-width: 1920px) {
    @import "xl";
}

