.app {

    section.navigation {

        .menu {
            display: block;
        }

        .bar {
            position: relative;
            width: calc(100% - 50px);
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .list {
                display: none;

                ul {
                    list-style: none;

                    li {
                        font-size: 10px;
                        margin-left: 5px;
                        margin-right: 5px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }

            .button {
                display: block;
            }
        }
    }
    section.products{
        .content{
            .flex-content {
                flex-direction: column;
                flex: 0 0 1;
                width: 100%;    
    
                .icons{
                    margin-top:20px;
                    flex-wrap: wrap;
                    justify-content: center;
    
                    img{
                        margin:5px;
                    }
                }
            }
        }
        .products{
            flex-direction: column;
            width:85%;

            .panel{
                .links{
                    display: flex;
                    flex-direction: row;
                    .life {
                        margin-right: 30px;
                    }
                    .unichem {
                        margin-right: 30px;
                    }
                }
            }
        }
    }

    section.hero {
        position: relative;
        width: 100%;
        height: 75vh;
    
        .header-image {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image: url(../images/hero-bg.png);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    
        .header-text {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
          transform: translate3d(-50%, -50%, 0);
          width:100%;
    
            .header-text-descript {
                h2 {
                  font-family: 'AvenirLight', sans-serif;
                  color: #414141;
                  font-size: 36px;
                  line-height: 40px;
                  text-align: center;
                  width: 70%;
                  margin: auto;
                  padding-left:0;
    
                }
            }
        }
    }

    section.overview{
        .content{
            .flex{
              flex-direction: column;
      
              .descript{
                .accordion{
                  font-size:18px;
                  padding:10px 0;
                }
              }
              .images{
                margin-top:30px;
                img{
                  width:100%;
                }
              }
            }
        }
        .pharmacy{
            flex-direction: column;
            width:100%;
            padding:50px 0;

            .text{
                width:90%;
                margin:auto;
            }
            .logos{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width:90%;

                img{
                    width: 150px;
                    height: 60px;
                }

            }
        }
    }

    section.devices-about{
        width:100%;
        justify-content: center;
    
        .content{
            width:90%;
            margin: 0 auto;
    
            .title{
                color:white;
                font-size:40px;
            }
    
            .descript{
                color:white;
                width:90%;
    
                p{
                    font-size:22px;
                    line-height:30px;
                }
                
            }
            
        }
      }

    section.highlight {
        height: 150px;

        p {
            max-width: 700px;
            span {
                font-size: 18px;
            }
        }

        .highlight-tab {
            transform: translate3d(-50%, -95%, 0);
        }
    }

    section.section-row {
        .products{
            flex-direction: column;
            width:85%;
        }
        
        .row {
            max-width: 700px;

            .cell {
                width:100%;
                .content {

                    .title {
                        font-size: 40px;
                    }

                    .subtitle {
                        font-size: 19px;
                    }

                    .descript {
                        font-size: 10px;
                        line-height: 17px;
                    }

                    .list {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        ul {
                            list-style: none;

                            li {
                                .item {

                                    .text {
                                        font-size: 13px;
                                    }

                                    .arrow {
                                        width: 12px;
                                        height: 12px;
                                        padding: 5px;

                                        &.circle {

                                            img {
                                                width: 80%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .instructions {
                        p {
                            font-size: 11px;

                            span {
                                padding-right: 10px;

                                &.bar {
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .line {

                .content {

                    .title {
                        font-size: 40px;
                    }

                    .subtitle {
                        font-size: 19px;
                    }

                    .descript {
                        font-size: 10px;
                        line-height: 17px;
                    }

                    .instructions {
                        p {
                            font-size: 11px;

                            span {
                                padding-right: 10px;

                                &.bar {
                                    padding-left: 10px;
                                }
                            }
                        }
                    }

                    .link {
                        margin-top: 50px;
                        margin-bottom: 50px;
                        width: 100%;

                        a {
                            font-size: 14px;
                            padding: 10px;
                            padding-left: 30px;
                            padding-right: 30px;
                        }
                    }
                }
            }

            &.product_7 {
                .cell-image {
                    .content {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    section.news{
        &.news-header {
          height: 250px;

          .header-copy {                    
              h1 {
                font-size: 30px;
                top: 170px;                
              }
          }
          .header-dark-bg {

          }
          .blue-bg {

          }
        }

        &.sub-path-container {
          &.show {

          }

          .main-path {

          }
          .header-arrow {

          }
          .sub-path {

          }
        }

        &.news-lists-container {   
      
            .news-lists {
                .date-container {
                    .title {

                    }
                    .date {

                    }
                }
                .news-container {

                    .header-copy {
                        h2 {

                        }
                    }
                    .main-copy {
                        .copy-block {
                          p {                        
                                     
                          }
                          .sub-header {

                          }
                        }  
                                                                         
                    }
                    .read-more-btn {                
                        p {

                        }

                        &:hover {
                          p {

                          }
                        }
                    }
                    .article-img {
                        display: none;
                    } 
                }
            }
        }
    }

    section.contact {
        .row {
            max-width: 760px;

            .cell {

                .content {
                    padding: 25px;
                    padding-top: 30px;
                    padding-bottom: 30px;

                    .title {
                        font-size: 40px;
                    }

                    .descript {
                        font-size: 10px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    section.video {
        .video-container {

            iframe {
                width: 520px;
                height: 300px;
                margin: 0 auto;
            }

            .close-btn {
                right: 0px;
            }
            
        }
        .overlay {

        }
    }
}