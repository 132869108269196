.desktop {
    display: none;
}

.mobile {
    display: block;
}

.app {
  overflow-x: hidden;

  section.navigation {
    .background {
      height: 70px;
    }

    .menu {
        display: block;
    }

    .bar {
        width: calc(100% - 32px);
        height: 70px;
        display:flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            position: relative;
            // float: left;
            img {
                // height: 50%;
            }
        }

        .list {
          display: none;
        }

        .button {
          display: block;
        }
    }
  }
  section.hero {
    position: relative;
    width: 100%;
    height: 70vh;

    .header-image {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../images/hero-bg.png);
        background-size: cover;
        background-position: 35% 50%;
        background-repeat: no-repeat;
    }

    .header-text {
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translate3d(-50%, -50%, 0);
      width:100%;

        .header-text-descript {
            h2 {
              font-family: 'AvenirLight', sans-serif;
              color: #414141;
              font-size: 25px;
              line-height: 29px;
              text-align: center;
              width: 90%;
              margin: auto;
              padding-left:0;

            }
        }
    }
  }

  section.overview{
    .content{
      .flex{
        flex-direction: column;

        .descript{
          .accordion{
            font-size:14px;
            padding:10px 0;
          }
        }
        .images{
          margin-top:30px;
          img{
            width:100%;
          }
        }
      }
    }
    .pharmacy{
      flex-direction: column;
      width:100%;
      padding:50px 0;

      .text{
          width:90%;
          margin:auto;

          p{
            width:100%;
          }
      }
      .logos{
        width: 90%;
        margin:auto;
        grid-row-gap: 0;
        grid-column-gap: 25px;

      }
  }
  }

  section.products{
    .content{
      .flex{
        flex-direction: column;

        .icons{
          margin-top:20px;
          flex-wrap: wrap;
          justify-content: initial;

          img{
            margin:5px;
          }
        }
      }
    }
    .products{
      flex-direction: column;

      .product-content{
        min-height:auto;

        .container{
          min-height:auto;

          .panel{
            .links{
                display: flex;
                flex-direction: column;
                .life {
                    margin-right: 10px !important;
                }
                .unichem {
                    margin-right: 10px !important;
                }
            }
          }
        }
      }
    }
  }

  section.devices-about{
    width:100%;
    justify-content: center;

    .content{
        width:90%;
        margin: 0 auto;

        .title{
            color:white;
            font-size:30px;
        }

        .descript{
            color:white;
            width:90%;

            p{
                font-size:16px;
                line-height:24px;
            }
            
        }
        
    }
  }


  section.section-row {

    .products{
      flex-direction: column;

      .product-content{
        min-height:auto;

        .container{
          min-height:auto;
        }
      }
    }
    .background {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      .image {

          &.center {
            background-position: center right;

            &.opaque {
              opacity: 0.2;
            }
          }

          &.left {
            width: 100%;
            background-position: center center;
          }

          &.right {
            width: 100%;
          }
      }

      .separator {
          display: none;
          &.center {
            background-position: center center;
          }

          &.left {
            width: 100%;
            height: 50%;
            top: 0%;
            left: 0;
            background-position: bottom center;
          }

          &.right {
            width: 100%;
            height: 60%;
            top: 45%;
            right: 0;
            background-position: top center;
          }

          &.white {
            background-image: url(../assets/images/website/products/product_separator-white-up.png)
          }

          &.blue {
            background-image: url(../assets/images/website/products/product_separator-blue-up.png)
          }
      }
    }

    .row {
      max-width: 700px;

      .cell {
          width: 100%;

          .cell-background {
            display: block;
          }

          .content {
              padding-top: 50px;
              padding-bottom: 50px;
              font-family: 'AvenirRoman', sans-serif;

              &.left {
                  padding-right: 25px;
                  float: left;
              }
              &.right {
                  padding-left: 25px;
              }

              .title {
                  font-size: 30px;
              }

              .subtitle {
                  font-size: 19px;
              }

              .descript {
                  font-size: 12px;
                  line-height: 17px;
                  color: #062757;
                  font-family: 'AvenirRoman', sans-serif;

                  .blue-text{
                    font-size:16px;
                    color:#104BA0;
                    line-height:20px;
                  }
              }

              .list {
                  width: 100%;
                  margin-top: 20px;
                  margin-bottom: 20px;

                  ul {
                      list-style: none;

                      li {
                          .item {

                              .text {
                                  font-size: 13px;
                              }

                              .arrow {
                                  width: 12px;
                                  height: 12px;
                                  padding: 5px;

                                  &.circle {

                                      img {
                                          width: 80%;
                                      }
                                  }
                              }
                          }
                      }
                  }
              }

              .instructions {
                  p {
                      font-size: 11px;

                      span {
                          padding-right: 10px;

                          &.bar {
                              padding-left: 10px;
                          }
                      }
                  }
              }
          }
      }

      .full-width{
          width:100%;

          .sub-info{
              color:#062757;
              font-size:16px;
              line-height:24px;
              width:100%;
          }
      }

      .line {

          .content {

              .title {
                  font-size: 40px;
              }

              .subtitle {
                  font-size: 19px;
              }

              .descript {
                  font-size: 10px;
                  line-height: 17px;
              }

              .instructions {
                  p {
                      font-size: 10px;

                      span {
                          padding-right: 5px;

                          &.bar {
                              padding-left: 5px;
                          }
                      }
                  }
              }

              .link {
                  margin-top: 50px;
                  margin-bottom: 20px;
                  width: 100%;

                  a {
                      font-size: 10px;
                      padding: 10px;
                      padding-left: 30px;
                      padding-right: 30px;
                  }
              }
          }
      }

      .plus {
        display: none;
      }

      &.product_7 {
          margin-left: 0;

          .cell-image {
              .content {
                  padding: 40px;
              }
          }
      }

      &.product_5 {
          .cell {
              .content {
                  .descript {
                      margin-bottom: 14px;
                  }
              }
          }
      }
    }
  }

  section.contact {
      .row {
          max-width: 760px;

          .cell {

              .content {
                  padding: 25px;
                  padding-top: 30px;
                  padding-bottom: 0px;

                  .title {
                      font-size: 40px;
                  }

                  .descript {
                      float: left;
                      font-size: 10px;
                      line-height: 20px;
                      padding-bottom: 20px;
                      margin-top: 0px;

                      .block {
                          width: 100%;
                          float: left;
                          display: block;

                          &.left {
                            width: 100%;
                            text-align: center;
                          }

                          &.center {
                            width: 100%;
                            display: none;
                          }

                          &.right {
                            width: 100%;
                            text-align: center;
                          }
                      }
                  }
              }
          }
      }
  }
  section.news{
    &.news-header {
      height: 180px;
      background-position: top;

      .header-copy {      
          h1 {
            font-size: 22px;
            top: 110px;
            left: 25px;
          }
      }
      .header-dark-bg {

      }
      .blue-bg {

      }
    }

    &.sub-path-container {
      &.show {

      }

      .main-path {

      }
      .header-arrow {

      }
      .sub-path {

      }
    }

    &.news-lists-container {   
  
      .news-lists {
        .date-container {
          .title {

          }
          .date {

          }
        }
        .news-container {


          .header-copy {
            h2 {

            }
          }
          .main-copy {
            .copy-block {
              p {                        
                         
              }
              .sub-header {

              }
            }                                                                
          }
          .read-more-btn {            
            p {

            }

            &:hover {
              p {

              }
            }
          }
          .article-img {
            display: none;
          }
        }
      }
    }
  }

  section.footer {
      width: 100%;
      text-align: center;
      border-top: 6px solid $color-blue;
      padding-top: 10px;
      padding-bottom: 10px;

      .content {
          height: 90px;

          .block {
              width: 100%;
              text-align: center;

              &.bottom {
                  padding-top: 10px;
              }

              .text {

                  p {
                      font-size: 10px;
                      padding: 0;
                  }
              }

              img {
                  height: 65px;
              }

          }
      }
  }

    section.products-title {
        padding: 30px;

        h2 {
            font-size: 40px;
        }
    }
}