.app {

    section.hero {
    
        .header-text {

    
            .header-text-descript {
                h2 {
                  margin-left:-70px;
    
                }
            }
        }
    }

    section.overview{
        .content{
            .flex{
                .descript{
                    flex:1 0 40%;
                }
            }
        }

        .pharmacy{
            flex-direction: column;

            .text{
                width:100%;
            }
            .logos{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width:100%;

                img{
                    height:80px;
                    width: 150px;
                }
            }
        }
    }
  
    section.products{
        .products{
            .product-content{
                min-height:450px;
  
                .container{
                    min-height:400px;

                    .panel{
                        .links{
                            display: flex;
                            flex-direction: row;
                            .life {
                                margin-right:30px;
                            }
                            .unichem {
                                margin-right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

  section.section-row{
      .products{
          .product-content{
              min-height:670px;

              .container{
                  min-height:600px;
              }
          }
      }
  }

  section.device-hero {
    position: relative;
    width: 100%;
    height: 75vh;

    .header-image {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/website/devices/devices-bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .header-text {
      position: absolute;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translate3d(-50%, -50%, 0);
      width:100%;

        .header-text-descript {
            h2 {
              font-family: 'AvenirLight', sans-serif;
              color: #ffffff;
              font-size: 36px;
              line-height: 40px;
              text-align: center;
              width: 70%;
              margin: auto;
              padding-left:0;
            }
        }
    }
}
}