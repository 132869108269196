html{
    scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.background-gray {
    background-color: $color-gray-light;
}

.background-white {
    background-color: $color-white;
}

.text-color-blue-dark {
    color: $color-blue-dark;
}

a {
    cursor: pointer !important;
}

.app {
    position: relative;
    width: 100%;
    z-index: 1;

    section.navigation {
        position: fixed;
        top: 0px;
        left: 0px;
        height: auto;
        width: 100%;
        z-index:1;

        .background {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 70px;
            background-color: #FCF3EC;
            opacity: 1;
            transform: translate3d(0%, -100%, 0);
        }

        .menu {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100vh;
            display: none;
            background-color: #FCF3EC;
            transform: translate3d(100%, 0%, 0);

            ul {
                margin-top: 90%;
                list-style: none;
                color: #414141;
                overflow: scroll;
                transform: translate3d(0%, -50%, 0);

                li {
                    font-family: $font-AzoSans-Regular;
                    font-size: 20px;
                    color: #414141;
                    float: left;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 2px;
                    cursor: pointer;
                    width: 100%;
                    text-align: center;

                    .text {
                        padding: 20px;

                        span {
                            a {
                                text-decoration: none;
                                color:#414141;
                            }
                        }
                    }

                    .line {
                        position: relative;
                        width: 100%;
                        padding-top: 5px;
                        overflow: hidden;
                        height: 3px;
                        margin: 0 auto;

                        .underline {
                            position: relative;
                            width: 100%;
                            height: 3px;
                            background-color: $color-blue-dark;
                            transform: translate3d(100%, 0%, 0)
                        }
                    }

                    .panel {
                        // background-color: white;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.2s ease-out;

                        a{
                            display: block;
                            height: 40px;
                            font-size: 16px;
                            text-decoration: none;
                            color: #414141;
                            line-height: 40px;
                        }
                    }
                }
            }
        }

        .bar {
            position: relative;
            width: calc(100% - 32px);
            height: 40px;
            max-width:1440px;
            margin:auto;

            .logo {
                width:115px;

                a{
                    display: block;
                    img{
                        display: block;
                        width:100%;
                    }
                }
            }

            .list {

                ul {
                    list-style: none;

                    li {
                        font-family: 'AvenirHeavy',sans-serif;
                        font-size: 10px;
                        color: #414141;
                        float: left;
                        text-transform: uppercase;
                        margin-left: 20px;
                        font-weight: bold;
                        letter-spacing: 2px;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                            color:#414141;
                        }

                        // .line {
                        //     position: relative;
                        //     width: 100%;
                        //     padding-top: 5px;
                        //     overflow: hidden;
                        //     height: 3px;

                        //     .underline {
                        //         position: relative;
                        //         width: 100%;
                        //         height: 1px;
                        //         background-color: $color-white;
                        //         transform: translate3d(100%, 0%, 0)
                        //     }
                        // }

                        .underline{
                            display: inline;
                            position: relative;
                            overflow: hidden;
                        }

                        .underline:after{
                            content: "";
                            position: absolute;
                            z-index: 1;
                            right: 0;
                            width: 0;
                            bottom: -5px;
                            background: #414141;
                            height: 1px;
                            transition-property: width;
                            transition-duration: 0.3s;
                            transition-timing-function: ease-out;
                        }

                        .underline:hover:after,
                        .underline:focus:after,
                        .underline:active:after {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }

                        .active-link{
                            border-bottom: 1px solid #414141;
                            padding-bottom:5px;
                        }
                    }
                }
            }

            .button {
                position: relative;
                // float: right;
                width: 30px;
                height: 24px;
                display: none;

                .bar {
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: #414141;
                    border-radius: 4px;

                    &.line-1 {
                        top:0;
                    }

                    &.line-2 {
                        top:10px;
                    }

                    &.line-3 {
                        bottom:0;
                    }
                }
            }
        }
    }


    section.hero {
        position: relative;
        width: 100%;
        height: 90vh;

        .header-image {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image: url(../images/hero-bg.png);
            background-size: cover;
            background-position: center center;
        }

        .header-text {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 434px;
            height: auto;
            display: inline-block;

            transform: translate3d(0%, -50%, 0);

            .header-text-descript {
                h2 {
                    font-family: 'AvenirLight', sans-serif;
                    color: #414141;
                    padding-left: 50px;
                    font-size: 42px;
                    width:127%;
                    line-height: 54px;
                    font-weight:300;
                    padding-bottom: 20px;

                    span{
                        font-family: 'AvenirHeavy', sans-serif;
                    }
                }
            }
        }

        .bottom-border{
            position:absolute;
            bottom:0;
            width:100%;
            height:24px;
            background-color: #FCF3EC;
        }
    }

    section.overview{
        padding-top:50px;
        margin-top:-50px;
        .content {
            position: relative;
            font-family: 'AvenirRoman', sans-serif;
            padding: 25px;
            padding-top: 60px;
            padding-bottom: 60px;
            max-width: 1440px;
            margin:auto;

            .title {
                font-size: 50px;
                color: $color-white;

                &.blue {
                    color: #104BA0;
                }
            }

            .half-line {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;

                .bar {
                    width: 86px;
                    height: 2px;
                    background-color: #414141;
                }
            }

            .full-line {
                width: 100%;
                margin-top: 14px;
                margin-bottom: 14px;

                .bar {
                    width: 100%;
                    height: 1px;
                }
            }

            .flex{
                display: flex;
                justify-content: space-between;
                .descript {
                    flex: 0 0 40%;
                    font-size: 16px;
                    line-height: 25px;
                    width: 100%;
                    // text-justify: inter-word;
                    color: #062757;

                    .blue-text{
                        font-size: 18px;
                        color: #062757;
                        line-height: 26px;
                    }

                    /* Style the buttons that are used to open and close the accordion panel */
                    .accordion {
                        background-color:transparent;
                        border-bottom:2px solid #DEE5F2;
                        color: #104BA0;
                        cursor: pointer;
                        padding: 20px 0;
                        width: 100%;
                        text-align: left;
                        outline: none;
                        transition: 0.4s;
                        font-size: 18px;
                    }

                    .panel {
                        // background-color: white;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.2s ease-out;

                        p{
                            padding:18px 0;
                            font-size:16px;
                            color:#062757;
                            line-height:24px;

                        }

                        a{
                            color:#062757;
                            margin-right: 20px;
                        }

                        .inline {
                            vertical-align: baseline;
                            margin: 0;
                            padding: 0;
                            display: inline;
                            font-size: inherit;
                            line-height: inherit;
                        }

                        a:visited{
                            color:#062757;
                        }

                        .pdf-links{
                            display: flex;
                            div{
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .accordion:after {
                        content: '\f3d0';
                        font-family: IonIcons;
                        font-size: 1.2em;
                        float: right;
                        margin-left: 5px;
                        transition: .4s all ease;
                        transform: rotate(0deg);
                    }

                    .active:after {
                        content: '\f3d0';
                        font-family: IonIcons;
                        font-size: 1.2em;
                        transform: rotate(-180deg);
                    }
                }
                .images{
                    flex:0 0 50%;

                    img{
                        display: block;
                        margin: 0px auto 60px;
                        max-width: 600px;
                    }
                }
            }
        }

        .pharmacy{
            padding:50px;
            max-width:1440px;
            margin:30px auto;
            background-color: #FCF3EC;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .text{
                width:50%;
                color:#062757;
                font-family: 'AvenirLight', sans-serif;

                h1{
                    font-size:2.5em;
                    margin:0 0 0.67em
                }

                p{
                    width:70%;
                    font-family: 'AvenirRoman', sans-serif;
                    line-height:30px;
                    font-size:22px;
                }
            }

            .logos {
                // display: grid;
                // grid-template-columns: 1fr 1fr;
                // grid-row-gap: 0;
                // grid-column-gap: 25px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                @media (max-width: 723px) {
                    div {
                        width: 46%;
                    }
                }

                img {
                    width: 125px;
                    height: 60px;
                    object-fit: contain;
                    display: block;
                    margin:0px auto;
                    padding:30px 0;
                }
            }
        }
    }

    section.products{
        padding-top:50px;
        margin-top:-50px;
        .content {
            position: relative;
            font-family: 'AvenirRoman', sans-serif;
            padding: 25px;
            padding-top: 60px;
            padding-bottom: 60px;
            max-width: 1440px;
            margin:auto;

            .title {
                font-size: 50px;
                color: $color-white;

                &.blue {
                    color: #104BA0;
                }
            }

            .half-line {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;

                .bar {
                    width: 86px;
                    height: 2px;
                    background-color: #414141;
                }
            }

            .flex{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .descript {
                    flex: 0 0 50%;
                    font-size: 18px;
                    line-height: 26px;
                    width: 100%;
                    color: #062757;
                }
                .icons{
                    flex: 0 0 40%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        .products{
            display: flex;
            justify-content: space-between;
            flex-direction:row;
            flex-wrap: wrap;
            max-width: 1440px;
            margin:auto;

            .single-product{
                flex:0 0 1;

                .product-image{
                    width:100%;
                    height: auto;

                    img{
                        display: block;
                        width:100%;
                    }
                }

                .product-content{
                    background-color: rgba(6, 39, 87, 0.05);
                    // min-height:670px;
                    margin-bottom:30px;
                    position: relative;

                    .container{
                        width:85%;
                        // min-height:600px;
                        margin:0px auto;
                        padding:30px 0;
                        font-family: 'AvenirRoman', sans-serif;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .shop-online {
                            width: 150px;
                            margin: 20px 0;
                            height: 40px;
                            background-color: #104BA0;
                            color: white;
                            font-size: 16px;
                            line-height: 40px;
                            display: block;
                            text-align: center;
                            text-decoration: none;
                        }

                        .flex-content {

                        .subtitle {
                            width:100%;
                            font-size: 24px;
                            color:#104BA0;
                            display: flex;
                            flex-direction: column;

                            span {
                                font-size:14px;
                                vertical-align: super;
                            }
                        }

                        .half-line {
                            width: 100%;
                            margin-top: 20px;
                            margin-bottom: 20px;

                            .bar {
                                width: 86px;
                                height: 2px;
                                background-color: $color-blue;
                            }
                        }

                        .full-line {
                            width: 100%;
                            margin-top: 14px;
                            margin-bottom: 14px;

                            .bar {
                                height: 1px;
                                background-color: $color-blue;
                            }
                        }

                        .descript {
                            padding:10px 0px;
                            font-size: 15px;
                            line-height: 25px;
                            // text-justify: inter-word;
                            color: #1F1F1F;

                            .inline-image {
                                display: inline-block;
                                width: 250px;
                                max-width: 50%;
                            }
                        }
                    }

                    .flex-buy{
                        .buy-now{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom:2px solid #DEE5F2;
                            color: #104BA0;
                            cursor: pointer;
                            margin:0 auto;
                            padding: 10px 0;
                            text-align: left;
                            outline: none;
                            transition: 0.4s;
                            font-size: 20px;
                        }

                        .accordion {
                            background-color:transparent;
                            border-bottom:2px solid #DEE5F2;
                            color: #104BA0;
                            cursor: pointer;
                            padding: 20px 0;
                            width: 100%;
                            text-align: left;
                            outline: none;
                            transition: 0.4s;
                            font-size: 18px;
                        }

                        .panel {
                            // background-color: white;
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.2s ease-out;

                            .links{
                                display: flex;
                                flex-direction: row;
                                padding-top: 12px;
                                .life {
                                    margin-right: 60px;
                                }
                                .unichem {
                                    margin-right: 60px;
                                }
                            }

                            .image-container{
                                margin:10px 0;
                                height:40px;
                                display: flex;
                                align-items: center;

                                img{
                                    width:100px;
                                }
                            }


                            h6{
                                margin: 1em 0;
                                color:#414141;

                            }

                            a{
                                display: block;
                                color:#414141;
                                font-size:0.8em;
                                padding:10px 0;
                            }


                        }

                        .accordion:after{
                            content: '\f3d0';
                            font-family: IonIcons;
                            font-size: 1.2em;
                            float: right;
                            margin-left: 5px;
                            transition: .4s all ease;
                            transform: rotate(0deg);
                        }

                        .active:after{
                            content: '\f3d0';
                            font-family: IonIcons;
                            font-size: 1.2em;
                            transform: rotate(-180deg);
                        }



                        div.pdf{
                            font-size:14px;
                            padding:20px 0 0;
                            color:#1F1F1F;
                            display: flex;
                            align-items: center;

                            a{
                                display: block;
                                color:#00AFF3;
                            }


                        }




                    }

                    }

                }
            }
        }
    }


    section.devices-about{
        padding-top:50px;
        margin-top:-50px;
        width:100%;
        height:90vh;
        max-width:1440px;
        margin: auto;
        background-image: url(../images/devices-about-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display:flex;
        justify-content: flex-end;
        align-items: center;

        .content{
            width:50%;
            font-family: 'AvenirRoman', sans-serif;

            .title{
                // padding-top: 50px;
                color:white;
                font-size:50px;
            }

            .half-line {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;

                .bar {
                    width: 86px;
                    height: 2px;
                    background-color: $color-blue;
                }
            }

            .descript{
                color:white;
                width:90%;

                p{
                    font-size:22px;
                    line-height:30px;
                }

                .visit{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom:2px solid #DEE5F2;
                    color: white;
                    cursor: pointer;
                    margin:40px 0;
                    padding: 10px 0;
                    text-align: left;
                    outline: none;
                    transition: 0.4s;
                    font-size: 30px;
                }

            }

            .logo{
                margin:40px 0;

                img{
                    width:180px;
                    height:auto;
                }
            }


        }
    }

    section.section-row {
        position: relative;
        width: 100%;
        height: auto;

        .products{
            display: flex;
            justify-content: space-between;
            flex-direction:row;
            flex-wrap: wrap;
            max-width: 1440px;
            margin:auto;

            .single-product{
                flex:0 0 49%;

                .product-image{
                    width:100%;
                    height: auto;

                    img{
                        display: block;
                        width:100%;
                    }
                }

                .product-content{
                    background-color: rgba(6, 39, 87, 0.05);
                    // min-height:670px;
                    margin-bottom:30px;
                    position: relative;

                    .container{
                        width:85%;
                        // min-height:600px;
                        margin:0px auto;
                        padding:30px 0;
                        font-family: 'AvenirRoman', sans-serif;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .flex-content{

                        .subtitle {
                            display: flex;
                            flex-direction: column;
                            width:100%;
                            font-size: 24px;
                            color:#104BA0;
                            // padding: 0 30px;
                        }

                        .half-line {
                            width: 100%;
                            margin-top: 20px;
                            margin-bottom: 20px;

                            .bar {
                                width: 86px;
                                height: 2px;
                                background-color: $color-blue;
                            }
                        }

                        .full-line {
                            width: 100%;
                            margin-top: 14px;
                            margin-bottom: 14px;

                            .bar {
                                width: 100%;
                                height: 1px;
                                background-color: $color-blue;
                            }
                        }

                        .descript {
                            padding:10px 0px;
                            font-size: 15px;
                            line-height: 25px;
                            width: 100%;
                            // text-justify: inter-word;
                            color: #1F1F1F;
                        }

                        /* Style the buttons that are used to open and close the accordion panel */
                        .accordion {
                            background-color:transparent;
                            border-bottom:2px solid #DEE5F2;
                            color: #104BA0;
                            cursor: pointer;
                            padding: 20px 0;
                            width: 100%;
                            text-align: left;
                            outline: none;
                            transition: 0.4s;
                            font-size: 18px;
                        }

                        .panel {
                            // background-color: white;
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.2s ease-out;

                            p{
                                padding:18px 0;
                                font-size:16px;
                                color:#1F1F1F;
                                line-height:24px;
                            }
                        }

                        .accordion:after {
                            content: '\f3d0';
                            font-family: IonIcons;
                            font-size: 1.2em;
                            float: right;
                            margin-left: 5px;
                            transition: .4s all ease;
                            transform: rotate(0deg);
                        }

                        .active:after {
                            content: '\f3d0';
                            font-family: IonIcons;
                            font-size: 1.2em;
                            transform: rotate(-180deg);
                        }
                    }

                    .flex-buy{
                        .buy-now{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom:2px solid #DEE5F2;
                            color: #104BA0;
                            cursor: pointer;
                            margin:0 auto;
                            padding: 10px 0;
                            text-align: left;
                            outline: none;
                            transition: 0.4s;
                            font-size: 20px;
                        }

                        .more-info{
                            font-size:14px;
                            padding:20px 0 0;
                            color:#1F1F1F;

                            a{
                                color:#00AFF3;
                            }
                        }
                    }

                    }

                }
            }
        }

        .background {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .image {
                position: absolute;
                top: 0%;
                width: 100%;
                height: 100%;
                background-size: cover;

                &.center {
                    background-position: center center;
                }

                &.left {
                    width: 60%;
                    left: 0;
                    background-position: center center;
                }

                &.right {
                    width: 60%;
                    right: 0;
                    background-position: right center;
                }

                &.product_7 {
                    background-position: -100px 0;
                }
            }

            .separator {
                position: absolute;
                top: 0%;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;

                &.center {
                    background-position: center center;
                }

                &.left {
                    width: 50%;
                    left: 0;
                    background-position: right center;
                }

                &.right {
                    width: 50%;
                    right: 0;
                    background-position: left center;
                }

                &.white {
                    background-image: url(../assets/images/website/products/product_separator-white.png)
                }

                &.blue {
                    background-image: url(../assets/images/website/products/product_separator-blue.png)
                }
            }
        }

        .row {
            position: relative;
            display: table;
            margin: 0 auto;
            width: 100%;
            max-width: 1440px;

            &.product_7 {
                .cell-image {
                    .content {
                        padding-left: 0;
                        margin-left: -70px;
                        padding-right: 70px;
                        padding-top: 180px;;
                    }
                }
            }

            &.product_1 {
                .cell-image {
                    .content {
                        .descript {
                            img {
                                width: auto;
                                max-height: 500px;
                            }
                        }
                    }
                }
            }

            &.product_5 {
                .cell {
                    .content {
                        .descript {
                            margin-bottom: 20px;
                        }
                    }
                }
            }



            .cell {
                position: relative;
                width: 50%;
                height: auto;
                float: left;

                .cell-background {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    display: none;

                    &.white {
                        background-size: 150%;
                        background-position: top center;
                        background-image: url(../assets/images/website/products/product_separator-white-up.png)
                    }

                    &.blue {
                        background-size: 150%;
                        background-position: top center;
                        background-image: url(../assets/images/website/products/product_separator-blue-up.png)
                    }
                }

                .content {
                    position: relative;
                    font-family: 'AvenirRoman', sans-serif;
                    padding: 25px;
                    padding-top: 60px;
                    padding-bottom: 60px;

                    &.left {
                        padding-right: 60px;
                    }
                    &.right {
                        padding-left: 60px;
                    }

                    .title {
                        font-size: 50px;
                        color: $color-white;

                        &.blue {
                            color: $color-blue-dark;
                        }
                    }

                    .subtitle {
                        font-size: 24px;
                    }

                    .half-line {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .bar {
                            width: 86px;
                            height: 2px;
                            background-color: $color-blue;
                        }
                    }

                    .full-line {
                        width: 100%;
                        margin-top: 14px;
                        margin-bottom: 14px;

                        .bar {
                            width: 100%;
                            height: 1px;
                        }
                    }

                    .descript {
                        font-size: 16px;
                        line-height: 25px;
                        width: 100%;
                        // text-justify: inter-word;
                        color: #062757;



                        .blue-text{
                            font-size: 18px;
                            color: #104BA0;
                            line-height: 26px;
                        }

                        img {
                            width: 100%;
                        }

                        a {
                            transition: opacity 0.1s ease-in-out;
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }

                    .list {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        ul {
                            list-style: none;

                            li {
                                .item {
                                    position: relative;
                                    padding-top: 20px;
                                    padding-bottom: 20px;
                                    border-bottom-width: 1px;
                                    border-bottom-style: solid;
                                    cursor: pointer;

                                    .text {
                                        position: relative;
                                        font-family: $font-AzoSans-Light;
                                        font-size: 17px;
                                        width: 80%;
                                    }

                                    .arrow {
                                        position: absolute;
                                        top: 15px;
                                        right: 0px;
                                        width: 12px;
                                        height: 12px;
                                        padding: 10px;

                                        transition: transform 0.3s ease-out;

                                        img {
                                            position: relative;
                                            width: 100%;
                                            transition: transform 0.3s ease-out;
                                        }

                                        &.circle {
                                            border: 1px solid $color-blue;
                                            border-radius: 100%;

                                            img {
                                                margin-top: 2.5px;
                                                margin-left: 1px;
                                                transform: rotate(-90deg);
                                            }
                                        }
                                    }
                                }

                                .descript {
                                    max-height: 0px;
                                    overflow: hidden;

                                    transition: max-height 0.6s ease-out;

                                    .text {
                                        padding: 10px;
                                        padding-left: 0px;

                                        span {

                                        }
                                    }
                                }

                                &.active {
                                    .descript {
                                        max-height: 400px;
                                    }

                                    .arrow {
                                        transform: rotate(-180deg);
                                    }
                                }

                                &:hover {
                                    .item {
                                        img {
                                            transform: translate3d(0%, 5px, 0);
                                        }

                                        .circle {
                                            transform: translate3d(5px, 0, 0);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .instructions {
                        p {
                            font-family: $font-AzoSans-Regular;
                            font-size: 13px;
                            line-height: 25px;

                            span {
                                padding-right: 20px;

                                &.bar {
                                    padding-left: 20px;
                                }
                            }

                            a {
                                text-decoration: none;
                                transition: opacity 0.1s ease-in-out;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }

                    &.dark-descript {
                        .title, .subtitle {
                            color: $color-blue-dark;
                        }

                        .descript {
                            color: $color-black;

                            a {
                                color: $color-black;
                            }
                        }

                        .half-line, .full-line {
                            .bar {
                                background-color: $color-blue;
                            }
                        }

                        .list {
                            ul {
                                li {
                                    .item {
                                        border-bottom-color: $color-blue-line;
                                    }

                                    .text {
                                        color: $color-blue-dark;
                                    }

                                    .descript {

                                        .text {

                                            span {
                                                color: $color-black;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .instructions {
                            span {
                                color: $color-black;

                                &.bar {
                                    color: $color-blue-dark;
                                }
                            }

                            a {
                                color: $color-blue-dark;
                            }
                        }
                    }

                    &.light-descript {
                        .title, .subtitle {
                            color: $color-blue;
                        }

                        .descript {
                            color: $color-white;
                        }

                        .half-line, .full-line {
                            .bar {
                                background-color: $color-blue-dark;
                            }
                        }

                        .list {
                            ul {
                                li {
                                    .item {
                                        border-bottom-color: $color-light-dark;
                                        border-top-color: $color-light-dark;

                                        a {
                                            text-decoration: none;
                                        }
                                    }

                                    .text {
                                        color: $color-white;
                                    }

                                    .descript {

                                        .text {

                                            span {
                                                color: $color-white;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .instructions {
                            span {
                                color: $color-white;

                                &.bar {
                                    color: $color-blue;
                                }
                            }

                            a {
                                color: $color-blue;
                            }
                        }
                    }
                }
            }

            .full-width{
                width:100%;

                .sub-info{
                    color:#062757;
                    font-size:18px;
                    line-height:26px;
                    width:65%;
                }
            }

            .line {
                position: relative;
                width: 100%;
                height: auto;
                float: left;
                text-align: center;

                .content {
                    font-family: $font-AzoSans-Regular;
                    padding: 25px;
                    padding-top: 60px;
                    padding-bottom: 60px;

                    .title {
                        font-size: 50px;
                        color: $color-white;
                    }

                    .subtitle {
                        font-size: 24px;
                        color: $color-white;
                    }

                    .half-line {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .bar {
                            width: 86px;
                            height: 2px;
                            background-color: $color-blue-light-line;
                            opacity: 0.5;
                            display: inline-block;
                            margin: 0 auto;
                        }
                    }

                    .full-line {
                        width: 100%;
                        margin-top: 30px;
                        margin-bottom: 30px;

                        .bar {
                            width: 50%;
                            height: 1px;
                            background-color: $color-blue-light-line;
                            opacity: 0.5;
                            display: inline-block;
                            margin: 0 auto;

                            &.opaque {
                                opacity: 0.2;
                            }
                        }
                    }

                    .descript {
                        font-size: 14px;
                        line-height: 25px;
                        width: 100%;
                        text-align: center;
                        color: $color-white;

                        img {
                            height: 100%;
                        }

                        a {
                            transition: opacity 0.1s ease-in-out;
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }

                    .list {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        ul {
                            list-style: none;

                            li {
                                .item {
                                    position: relative;
                                    padding-top: 20px;
                                    padding-bottom: 20px;
                                    border-bottom-width: 1px;
                                    border-bottom-style: solid;
                                    cursor: pointer;

                                    .text {
                                        position: relative;
                                        font-family: $font-AzoSans-Light;
                                        font-size: 17px;
                                        width: 80%;
                                    }

                                    .arrow {
                                        position: absolute;
                                        top: 25px;
                                        right: 0px;
                                        width: 15px;

                                        img {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .instructions {
                        p {
                            font-family: $font-AzoSans-Regular;
                            font-size: 13px;

                            span {
                                padding-right: 20px;
                                color: $color-white;

                                &.bar {
                                    padding-left: 20px;
                                    color: $color-blue;
                                }
                            }

                            a {
                                text-decoration: none;
                                color: $color-blue;
                                transition: opacity 0.1s ease-in-out;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }

                    .link {
                        margin-top: 50px;
                        margin-bottom: 50px;
                        width: 100%;

                        a {
                            font-family: $font-AzoSans-Regular;
                            font-size: 17px;
                            border: 1px solid $color-blue;
                            border-radius: 50px;
                            padding: 15px;
                            text-decoration: none;
                            padding-left: 35px;
                            padding-right: 35px;

                            transition: background-color 0.3s ease-in-out;

                            &:hover {
                                background-color: $color-blue;
                            }
                        }
                    }
                }
            }

            .plus {
                position: absolute;
                pointer-events: none;

                img {
                    width: 10px;
                }

                top: 50%;
                left: 50%;

                &.left {
                    transform: rotate(-90deg) translate3d(50%, 0%, 0);
                }

                &.right {
                    transform: rotate(90deg) translate3d(-50%, 100%, 0)
                }
            }
        }
    }

    section.contact {
        position: relative;
        width: 100%;
        height: auto;
        background-color: $color-white;

        .row {
            position: relative;
            display: table;
            margin: 0 auto;
            width: 100%;
            max-width: 1080px;

            .cell {
                position: relative;
                width: 100%;
                height: auto;
                float: left;
                text-align: center;

                .content {
                    font-family: 'AvenirRoman', sans-serif;
                    padding: 25px;
                    padding-top: 60px;
                    padding-bottom: 60px;

                    .title {
                        font-size: 50px;
                        color: $color-blue-dark;
                    }

                    .subtitle {
                        font-size: 24px;
                    }

                    .half-line {
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .bar {
                            width: 86px;
                            height: 2px;
                            display: inline-block;
                            margin: 0 auto;
                            background-color: $color-blue;
                        }
                    }

                    .full-line {
                        width: 100%;
                        margin-top: 14px;
                        margin-bottom: 14px;

                        .bar {
                            width: 100%;
                            height: 1px;
                            display: inline-block;
                            margin: 0 auto;
                            background-color: $color-blue;
                        }
                    }

                    .descript {
                        position: relative;
                        font-size: 13px;
                        line-height: 25px;
                        width: 100%;
                        color: $color-black;
                        margin-top: 30px;

                        .block {
                            position: relative;
                            height: auto;
                            display: table-cell;

                            a {
                                color: $color-blue-dark;
                                text-decoration: none;

                                transition: opacity 0.1s ease-in-out;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }

                            &.left {
                                width: 35%;
                                text-align: right;
                            }

                            &.center {
                                width: 30%;
                                text-align: center;
                            }

                            &.right {
                                width: 35%;
                                text-align: left;
                            }

                            .text {

                                img {
                                    width: 40%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.news{
        &.news-header {
            background-image: url(../assets/images/website/news/news-header-bg.jpg);
            background-position: 10% 30%;
            background-size: cover;
            height: 390px;
            position: relative;

            .header-copy {
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 1026px;
                margin: 0 auto;

                h1 {
                    color: $color-white;
                    font-family: $font-AzoSans-Light;
                    font-size: 35px;
                    position: absolute;
                    top: 290px;
                    left: 25px;
                }
            }
            .header-dark-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #23232f;
                opacity: 0.5;
            }
            .blue-bg {
                position: absolute;
                width: 100%;
                height: 8px;
                bottom: -8px;
                background-color: $color-blue;
            }
        }

        &.sub-path-container {
            margin: 40px 0px -15px 25px;
            display: none;

            &.show {
                display: block;
            }

            .main-path {
               font-family: $font-AzoSans-Regular;
               color: $color-blue-dark;
               font-size: 13px;
               display: inline-block;
            }
            .header-arrow {
                width: 5px;
                margin: 0px 10px;
            }
            .sub-path {
                font-family: $font-AzoSans-Regular;
                color: $color-blue-dark;
                font-size: 13px;
                display: inline-block;
            }
        }

        &.news-lists-container {
            width: calc(100% - 50px);
            margin: 40px auto 40px auto;
            max-width: 1026px;
            opacity: 0;
            transition: all .8s;

            .news-lists {
                .date-container {
                    .title {
                        font-family: $font-AzoSans-Regular;
                        color: $color-news-header-grey;
                        font-size: 14px;
                        margin-bottom: 3px;
                    }
                    .date {
                        font-family: $font-AzoSans-Regular;
                        color: $color-blue;
                        font-size: 14px;
                    }
                }
                .news-container {
                    padding-bottom: 25px;
                    border-bottom: 1px solid $color-news-header-grey;
                    width: 100%;

                    .header-copy {
                        border-bottom: 1px solid $color-blue;
                        margin-bottom: 20px;

                        h2 {
                            font-family: $font-AzoSans-Light;
                            color: $color-blue-dark;
                            font-size: 19px;
                            line-height: 25px;
                            margin-top: 10px;
                        }
                    }
                    .main-copy {
                        .copy-block {
                            p {
                                font-family: $font-AzoSans-Regular;
                                text-align: justify;
                                color: $color-black;
                                font-size: 14px;
                                line-height: 17px;

                                a {
                                    color: $color-black;
                                }
                            }
                            .sub-header {
                                font-family: $font-AzoSans-Regular;
                                color: $color-blue-dark;
                                font-size: 19px;
                                line-height: 25px;
                                margin: 20px 0px;
                            }
                            .short-copy {
                                .more-dots {
                                    &.hide {
                                        display: none;
                                    }
                                }
                            }
                            .more-content {
                                display: none;

                                &.show {
                                    display: block;
                                }
                            }
                            .main-copy-container {

                            }
                        }
                    }
                    .btn-link {
                        text-decoration: none;
                        display: inline-block;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .read-more-btn {
                        border: 1px solid $color-blue;
                        border-radius: 20px;
                        width: 130px;
                        transition: background-color 0.3s ease-in-out;
                        margin-top: 20px;

                        &.hide {
                            display: none;
                        }

                        p {
                            text-align: center;
                            padding: 10px 10px 8px 10px;
                            font-family: $font-AzoSans-Regular;
                            font-size: 14px;
                            color: $color-blue-dark;
                            transition: background-color 0.4s ease-in-out;
                        }

                        &:hover {
                            background-color: $color-blue;

                            p {
                                color: $color-white;
                            }
                        }
                    }
                    .article-img {
                        display: block;
                    }
                }
            }
        }
    }

    section.footer {
        width: 100%;
        text-align: center;
        border-top: 6px solid $color-blue;

        .content {
            width: 100%;
            height: 100px;
            padding-top: 20px;
            padding-bottom: 20px;

            .block {

                &.bottom {
                    padding-top: 10px;
                }

                img {
                    height: 60px;
                }

                .text {

                    p {
                        font-family: $font-AzoSans-Light;
                        font-size: 13px;
                        color: $color-blue-dark;
                        width: 100%;
                        height: auto;
                    }
                }

            }
        }
    }

    section.video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: none;

        .video-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 11;

            iframe {
                width: 300px;
                height: 160px;
                margin: 0 auto;
            }

            .close-btn {
                position: absolute;
                right: 0px;
                top: -40px;
                z-index: 11;
                background: transparent;
                width: 30px;

                img {
                    width: 100%;
                }
            }

        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.7;
            z-index: 10;
        }



    }
}