@font-face {
  font-family: 'AzoSans-Regular';
  src: url('../fonts/AzoSans-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/AzoSans-Regular.otf')  format('opentype'),
       url('../fonts/AzoSans-Regular.woff') format('woff'), url('../fonts/AzoSans-Regular.ttf')  format('truetype'), url('../fonts/AzoSans-Regular.svg#AzoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-Light';
  src: url('../fonts/AzoSans-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/AzoSans-Light.otf')  format('opentype'),
       url('../fonts/AzoSans-Light.woff') format('woff'), url('../fonts/AzoSans-Light.ttf')  format('truetype'), url('../fonts/AzoSans-Light.svg#AzoSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-Thin';
  src: url('../fonts/AzoSans-Thin.eot?#iefix') format('embedded-opentype'),  url('../fonts/AzoSans-Thin.otf')  format('opentype'),
       url('../fonts/AzoSans-Thin.woff') format('woff'), url('../fonts/AzoSans-Thin.ttf')  format('truetype'), url('../fonts/AzoSans-Thin.svg#AzoSans-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirHeavy';
  src: url('../fonts/AvenirLTStd-Heavy.eot');
  src: url('../fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
      url('../fonts/AvenirLTStd-Heavy.woff') format('woff'),
      url('../fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
      url('../fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirRoman';
  src: url('../fonts/AvenirLTStd-Roman.eot');
  src: url('../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
      url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
      url('../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
      url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirBook';
  src: url('../fonts/AvenirLTStd-Book.eot');
  src: url('../fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
      url('../fonts/AvenirLTStd-Book.woff') format('woff'),
      url('../fonts/AvenirLTStd-Book.ttf') format('truetype'),
      url('../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLight';
  src: url('../fonts/AvenirLTStd-Light.eot');
  src: url('../fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AvenirLTStd-Light.woff2') format('woff2'),
      url('../fonts/AvenirLTStd-Light.woff') format('woff'),
      url('../fonts/AvenirLTStd-Light.ttf') format('truetype'),
      url('../fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}